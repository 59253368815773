#root {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('images/logo.png');
    background-position: 1rem 1rem;
    background-size: 125px;
    background-repeat: no-repeat;
}
.App {
    display: flex;
    flex-direction: column; /* Updated */
    align-items: center;
    max-height: 90vh;
    overflow-y: auto;
}

.App:before {
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
}

.App.bg-1:before {
    background-image: url('images/bg/1.png');
}

.App.bg-2:before {
    background-image: url('images/bg/2.png');
}

.App.bg-3:before {
    background-image: url('images/bg/3.png');
}

.App.bg-4:before {
    background-image: url('images/bg/4.png');
}
.App > .app-title {
    position: fixed;
    left: 13rem;
    top: 1rem;
}

.App > .app-title h1 {
    font-size: 2rem;
}

.App > .header {
    position: fixed;
    top: 0;
    right: 0;
    margin: 1rem;
    width: auto;
}

.App > .header > .btn {
    margin: 0 0.5rem;
    white-space: nowrap;
}

.App > .container {
    margin: 0.25rem auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}

.App > .container h2 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
}

.normal-case {
    text-transform: unset !important;
}

.btn-info {
    background-color: #71cddd;
    border-color: #71cddd;
}

.btn-info:hover {
    background-color: #59a3b0;
    border-color: #59a3b0;
}

.btn-dark {
    background-color: #606060;
    border-color: #606060;
}

.btn-dark:hover {
    background-color: #3d3d3d;
    border-color: #3d3d3d;
}

.btn-primary {
    background-color: #f47323;
    border-color: #f47323;
}

.btn-primary:hover {
    background-color: #c15817;
    border-color: #c15817;
}

.btn-success, .bg-success {
    background-color: #f47323 !important;
    border-color: #f47323 !important;
}

.btn-success:hover, .bg-success:hover {
    background-color: #c15817 !important;
    border-color: #c15817 !important;
}

.btn-warning {
    background-color: #71cddd;
    border-color: #71cddd;
}

.btn-warning:hover {
    background-color: #59a3b0;
    border-color: #59a3b0;
}


.error {
    color: red;
}

.form-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

label {
    font-weight: bold;
    margin-right: 1rem;
    white-space: nowrap;
}

p {
    margin-top: 1rem;
}

@media print {
    .header, 
    .search-form, 
    .App > .container > .justify-content-end.mt-3,
    button {
        display: none !important;
    }
    .container {
        max-width: none;
        page-break-inside: avoid;
    }
    .App > .container {
        padding-bottom: 0;
    }
    .legs {
        margin-bottom: 0;
    }
}