.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    flex-direction: column;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border: 0;
    border-radius: 0 0 4px 4px;
    padding: 20px;
    max-width: 85vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 90vh;
    overflow-y: auto;
}

.modal-header {
    background-color: #fff;
    max-width: 85vw;
    position: relative;
    border: 0;
    padding: 20px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    color: #999;
    padding: 0;
}

.close-icon {
    line-height: 1;
}

.close-icon:hover {
    color: #333;
}
