.available-items {
    margin-top: 20px;
}

.available-items h3 {
    font-size: 18px;
}

.modal-content .info svg {
    display: none;
}
