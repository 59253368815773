.item-list {
    padding: 0;
    max-height: 43vh;
    overflow: scroll;
}

.item-info {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}
.auto .item-info {
    list-style: none;
    border: none;
}
.auto .item-info > div {
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: none;
    transition: box-shadow 500ms;
}
.auto .item-info > div:hover {
    box-shadow: 0px 0px 4px black;
}
.auto .item-info .rate-image > img {
    max-width: 200px;
}

.item-info.active {
    background-color: lightgray;
}

.item-info p {
    margin-bottom: 5px;
}

.item-info ul {
    list-style-type: disc;
    margin-left: 20px;
}

.toggle-details {
    cursor: pointer;
    font-style: italic;
    color: navy;
}

.modal-content .btn-success,
.modal-content .btn-info,
.selected-item .btn-primary,
.App.request .selected-item button,
.App.review .selected-item .btn-success {
    display: none;
}

.selected-item .info h4 {
    margin: 0 1rem;
}

/* Air */
.App.air .item-info {
    text-align: left;
}
.item-info .legs,
.item-info .legs * {
    list-style: none;
}

.item-info .legs ul:first-child {
    border-top: 3px solid darkgray;
}

.item-info .legs .schedule {
    border-top: 1px dotted darkgray;
}
