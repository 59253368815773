.search-form .form-group {
    min-width: unset;
}

.search-form .form-group {
    margin-bottom: 1rem;
}


.search-form .form-group [type=submit].btn-secondary {
    cursor: progress;
}

.search-form {
    position: relative;
}

.search-form .skip {
    position: absolute;
    top: 0;
    right: 0;
}

.search-form input:focus {
    outline: none;
    box-shadow: none;
    border-radius: 0 4px 4px 0 !important;
}

input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    content: "\25BC";
    color: #555;
    padding: 0 5px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after {
    color: #f47324;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
    z-index: 1;
}
