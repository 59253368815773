/* MediaCarousel.css */

.media-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-container {
    position: relative;
}

.carousel-items {
    display: flex;
    overflow: hidden;
}

.media-item {
    flex: 0 0 auto;
    display:none;
    transition: opacity 0.3s ease-in-out;
}

.media-item.active {
    display: block;
}

.media-item img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-height: 300px;
    max-width: 300px;
}

.media-description {
    background-color: #f5f5f5;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
}

.prev-slide-btn,
.next-slide-btn {
    position: absolute;
    bottom: 5rem;
    transform: translateY(-50%);
    padding: 10px;
    font-size: 16px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.prev-slide-btn {
    left: 10px;
}

.next-slide-btn {
    right: 10px;
}
