.Login-container {
    background-color: #eeeeee;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
}

.Login-container h2 {
    margin-bottom: 1.5rem;
}

.login-button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}

.Login-container form {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.login-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}