/* AutoComplete */
.loading-indicator,
.no-suggestions,
.suggestions-list li {
    padding: 0.5rem;
}

.loading-indicator,
.no-suggestions,
.suggestions-list {
    position: absolute;
    top: 3rem;
    left: 3.8rem;
    width: calc(100% - 3.7rem);
    background-color: rgba(255,255,255,0.9);
    border-radius: 4px;
    z-index: 1;
    border: 1px solid #ccc;
}

.loading-indicator::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 3px solid #ccc;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.suggestions-list li {
    cursor: pointer;
}

.suggestions-list li em {
    font-size: 0.85rem;
}

.suggestions-list li:hover {
    background-color: #f1f1f1;
}
