.result {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    display:flex;
    justify-content: space-between;
    cursor: pointer;
}

.auto .result {
    border: none;
}
.auto .result .details {
    text-align: left;
}
.auto .result > div:first-child {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 7rem;
}

.result.active {
    background-color: lightgray;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 33%;
}

.air .result .header {
    width: 100%;
}

.header svg {
    width: 100px;
    margin: 0.25rem;
}

.details {
    flex-grow: 1;
}

.air .result .details {
    text-align: center;
}

.details h3 {
    margin: 0;
    font-size: 1.2rem;
}

.details p {
    margin: 5px 0;
    font-size: 1rem;
}

.location p {
    margin: 5px 0;
    font-size: 0.9rem;
}

.amenities {
    margin-top: 10px;
}

.amenities h4 {
    margin: 0 0 5px;
    font-size: 1rem;
}

.amenities button {
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.amenities ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.amenities ul.show {
    display: block;
}

.amenities li {
    margin-bottom: 5px;
    font-size: 0.9rem;
    margin: 1rem;
    white-space: nowrap;
}
